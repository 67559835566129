<template>
  <div class="goodsSearch">
    <div v-if="httpShow">
      <div
        class="main-card"
        style="display: flex"
        v-if="searchGoodsData.list.length != 0"
      >
        <div class="left">
          <div class="l-category" v-if="categoryLists.length != 0">
            <div
              class="item"
              :class="index == 999 ? 'item-active' : ''"
              @click="handleClick(categoryList, 999)"
            >
              <div style="width: 20px; height: 10px">
                <i class="el-icon-caret-right" v-if="index == 999"></i>
              </div>
              <div>
                <span>{{ categoryList.name }}</span>
              </div>
            </div>
            <div
              class="item"
              v-for="(item, i) in categoryLists"
              :key="item.category_id"
              :class="index == i ? 'item-active' : ''"
              @click="handleClick(item, i)"
            >
              <div style="width: 20px; height: 10px">
                <i class="el-icon-caret-right" v-if="index == i"></i>
              </div>
              <div>
                <span>{{ item.name }}</span>
              </div>
            </div>
            <div class="item" v-if="show">
              <div style="width: 20px; height: 10px"></div>
              <div
                @click="
                  categoryLists = categoryList.list;
                  show = false;
                "
              >
                <span
                  >更多
                  <i class="el-icon-arrow-down"></i>
                </span>
              </div>
            </div>
          </div>

          <div style="margin-bottom: 10px">
            <svg
              enable-background="new 0 0 15 15"
              viewBox="0 0 15 15"
              x="0"
              y="0"
              class="shopee-svg-icon"
              style="width: 12px; height: 12px; margin-right: 10px"
            >
              <g>
                <polyline
                  fill="none"
                  points="5.5 13.2 5.5 5.8 1.5 1.2 13.5 1.2 9.5 5.8 9.5 10.2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-miterlimit="10"
                  stroke="#111"
                ></polyline>
              </g>
            </svg>
            <span>条件筛选</span>
          </div>
          <div class="l-price">
            <div style="margin-bottom: 10px">价格范围</div>
            <div>
              <el-input
                v-model="minNum"
                placeholder="RM最小值"
                size="mini"
                style="width: 70px"
              ></el-input>
              <span style="margin: 0 4px; color: rgba(0, 0, 0, 0.8)">——</span>
              <el-input
                size="mini"
                v-model="maxNum"
                placeholder="RM最大值"
                style="width: 70px"
              ></el-input>
            </div>
            <el-button
              size="mini"
              type="primary"
              style="min-width: 190px; margin-top: 20px"
              @click="getGoodsList"
              >套用</el-button
            >
          </div>
          <div class="l-evaluation">
            <div style="margin-bottom: 10px">评价</div>
            <div
              v-for="(item, i) in rate"
              :key="i"
              class="item"
              style="display: flex"
            >
              <el-rate
                disabled-void-icon-class="el-icon-star-off"
                disabled-void-color="#ffce3d"
                disabled
                v-model="item.num"
              ></el-rate>
              <span v-if="i != 0">& 以上</span>
            </div>
          </div>
          <div class="l-condition">
            <div style="margin-bottom: 10px">商品状况</div>
            <el-checkbox-group v-model="checkList" @change="getGoodsList">
              <div style="margin-bottom: 10px">
                <el-checkbox label="0">新</el-checkbox>
              </div>
              <div>
                <el-checkbox label="1">二手</el-checkbox>
              </div>
            </el-checkbox-group>
          </div>
          <div>
            <el-button
              @click="handleRemove"
              size="mini"
              type="primary"
              style="min-width: 190px; margin-top: 20px"
              >清除全部</el-button
            >
          </div>
        </div>
        <div class="right">
          <div
            style="
              display: flex;
              align-items: center;
              color: #555;
              margin-bottom: 20px;
            "
          >
            <svg
              style="width: 16px; height: 22px; margin-right: 10px"
              viewBox="0 0 18 24"
              class="shopee-svg-icon icon-hint-bulb"
              fill="#555"
            >
              <g transform="translate(-355 -149)">
                <g transform="translate(355 149)">
                  <g fill-rule="nonzero" transform="translate(5.4 19.155556)">
                    <path
                      d="m1.08489412 1.77777778h5.1879153c.51164401 0 .92641344-.39796911.92641344-.88888889s-.41476943-.88888889-.92641344-.88888889h-5.1879153c-.51164402 0-.92641345.39796911-.92641345.88888889s.41476943.88888889.92641345.88888889z"
                    ></path>
                    <g transform="translate(1.9 2.666667)">
                      <path
                        d="m .75 1.77777778h2.1c.41421356 0 .75-.39796911.75-.88888889s-.33578644-.88888889-.75-.88888889h-2.1c-.41421356 0-.75.39796911-.75.88888889s.33578644.88888889.75.88888889z"
                      ></path>
                    </g>
                  </g>
                  <path
                    d="m8.1 8.77777718v4.66666782c0 .4295545.40294373.7777772.9.7777772s.9-.3482227.9-.7777772v-4.66666782c0-.42955447-.40294373-.77777718-.9-.77777718s-.9.34822271-.9.77777718z"
                    fill-rule="nonzero"
                  ></path>
                  <path
                    d="m8.1 5.33333333v.88889432c0 .49091978.40294373.88888889.9.88888889s.9-.39796911.9-.88888889v-.88889432c0-.49091977-.40294373-.88888889-.9-.88888889s-.9.39796912-.9.88888889z"
                    fill-rule="nonzero"
                  ></path>
                  <path
                    d="m8.80092773 0c-4.86181776 0-8.80092773 3.97866667-8.80092773 8.88888889 0 1.69422221.47617651 3.26933331 1.295126 4.61333331l2.50316913 3.9768889c.30201078.4782222.84303623.7697778 1.42482388.7697778h7.17785139c.7077799 0 1.3618277-.368 1.7027479-.9617778l2.3252977-4.0213333c.7411308-1.2888889 1.1728395-2.7786667 1.1728395-4.37688891 0-4.91022222-3.9409628-8.88888889-8.80092777-8.88888889m0 1.77777778c3.82979317 0 6.94810087 3.18933333 6.94810087 7.11111111 0 1.24444441-.3168334 2.43022221-.9393833 3.51466671l-2.3252977 4.0213333c-.0166754.0284444-.0481735.0462222-.0833772.0462222h-7.07224026l-2.43461454-3.8648889c-.68184029-1.12-1.04128871-2.4053333-1.04128871-3.71733331 0-3.92177778 3.11645483-7.11111111 6.94810084-7.11111111"
                  ></path>
                </g>
              </g>
            </svg>
            <div style="font-size: 16px">
              '<span style="color: #ee4d2d">{{ searchValue }}</span
              >'
            </div>
            <span>搜索的结果</span>
          </div>
          <div v-if="searchGoodsData.list.length != 0">
            <div class="top">
              <div class="lefts">
                <span>筛选</span>
                <div
                  class="l-btn"
                  :class="sxIndex == i ? 'l-btn-active' : ''"
                  v-for="(item, i) in sxData"
                  :key="i"
                  @click="qiehuan(i)"
                >
                  {{ item.name }}
                </div>
                <div class="l-btna">
                  <span
                    :style="{
                      color: jgIndex == 0 || jgIndex == 1 ? '#ee4d2d' : '',
                    }"
                  >
                    {{ jgname }}</span
                  >

                  <i class="el-icon-arrow-down"></i>
                  <div class="jiageList">
                    <div
                      v-for="(item, i) in jgData"
                      :key="i"
                      @click="jiage(item, i)"
                      style="
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                      "
                      class="zxc"
                    >
                      价格：{{ item.label }}
                      <i
                        v-if="jgIndex == i"
                        class="el-icon-check"
                        style="color: #ee4d2d"
                      ></i>
                    </div>
                  </div>
                </div>
              </div>
              <div class="r-feny">
                <div style="width: 100px">
                  <span style="color: #ee4d2d"> {{ page }}</span
                  >/{{ Math.ceil(searchGoodsData.total / 70) }}
                </div>
                <el-pagination
                  background
                  :current-page="page"
                  :page-size="pageSize"
                  layout="prev, next"
                  :total="searchGoodsData.total"
                  @current-change="pageChange"
                >
                </el-pagination>
              </div>
            </div>
            <div class="search-list">
              <SearchGoods
                :data="item"
                v-for="item in searchGoodsData.list"
                :key="item.id"
              ></SearchGoods>
            </div>
            <div
              style="display: flex; justify-content: center; margin-top: 40px"
              class="cvb"
            >
              <el-pagination
                :current-page="page"
                :page-size="pageSize"
                layout="prev, pager, next"
                :total="searchGoodsData.total"
                @current-change="pageChange"
              >
              </el-pagination>
            </div>
          </div>
          <div
            v-else
            style="
              font-siez: 18px;
              text-align: center;
              color: rgba(0, 0, 0, 0.54);
              margin-top: 140px;
            "
          >
            <img style="width: 134px; height: 134px" :src="quesheng" alt="" />
            <div>哎呀！我们找不到任何商品。试试关闭一些筛选？</div>
            <div style="margin: 10px 0">or</div>
            <div>
              <el-button type="primary">重设筛选</el-button>
            </div>
          </div>
        </div>
      </div>
      <div
        class="main-card"
        style="text-align: center; padding: 100px 0; font-size: 18px"
        v-if="searchGoodsData.list.length == 0 && $store.state.searchType == 1"
      >
        <img style="width: 134px; height: 134px" :src="searchImg" alt="" />
        <div>找不到结果</div>
        <div style="color: #00000084; margin-top: 10px">
          试试不同或常见的关键字
        </div>
      </div>
      <div
        class="main-card"
        style="text-align: center; padding: 100px 0; font-size: 18px"
        v-if="searchGoodsData.list.length == 0 && $store.state.searchType == 0"
      >
        <img style="width: 134px; height: 134px" :src="searchImg" alt="" />
        <div>在这个商店中找不到商品</div>
        <div style="color: #00000084; margin-top: 10px">
          试试不同或常见的关键字
        </div>
        <div style="margin-top: 30px">
          <el-button
            @click="sousuo"
            type="primary"
            style="min-width: 224px; min-height: 48px; font-size: 16px"
            >在Shopee搜索全部商品</el-button
          >
        </div>
      </div>
    </div>
    <div v-else style="text-align: center; padding-top: 20px">
      <img :src="lodingImg" alt="" />
    </div>
  </div>
</template>
<script>
import SearchGoods from "@/components/category/SearchGoods";
import { searchGoods } from "@/api/home.js";
export default {
  components: {
    SearchGoods,
  },
  data() {
    return {
      searchImg: require("@/assets/img/home/search.png"),
      quesheng: require("@/assets/img/home/quexing.png"),
      categoryList: [],
      categoryLists: [],
      show: true,
      index: 999,
      rate: [{ num: 5 }, { num: 4 }, { num: 3 }, { num: 2 }, { num: 1 }],
      checkList: [],
      minNum: "",
      maxNum: "",
      sxData: [{ name: "热门" }, { name: "最新" }, { name: "最热销" }],
      sxIndex: 0,
      jgname: "价格",
      jgData: [
        { label: "低至高", value: 0 },
        { label: "高至低", value: 1 },
      ],
      jgIndex: 99,
      searchGoodsData: {
        list: [],
      }, //搜索结果
      page: 1, //页数
      pageSize: 70,
      order: "", //价格排序
      category_id: sessionStorage.CATEGORYID,
      searchValue: "",
      lodingImg: require("@/assets/img/app/http.gif"),
      httpShow: false,
    };
  },
  created() {
    // this.$store.state.homeFootData.forEach((item) => {
    //   if (item.category_id == sessionStorage.CATEGORYID) {

    //     this.categoryList = item;
    //     if (this.categoryList.list) {
    //       let arr = this.categoryList.list.slice(0, 5);
    //       this.categoryLists = arr;
    //     }
    //   }
    // });
    // if (this.$route.query.index && this.$route.query.index != "") {
    //   this.index = this.$route.query.index;
    //   this.categoryLists = this.categoryList.list;
    //   this.show = false;
    //   this.category_id =
    //     this.categoryLists[this.$route.query.index].category_id;
    // }
    this.searchValue = this.$store.state.searchValue;
    this.getGoodsList();
  },
  methods: {
    getGoodsList() {
      searchGoods({
        shop_id:
          this.$store.state.searchType == 0 ? this.$route.query.shop_id : "",
        type: this.sxIndex,
        goods_status:
          this.checkList.length != 0 ? this.checkList.join(",") : "",
        page_size: this.pageSize,
        keyword: this.$store.state.searchValue,
        order: this.order,
        category_id: this.category_id,
        price_min: this.minNum,
        price_max: this.maxNum,
        brand_id: "",
        page: this.page,
      }).then((res) => {
        if (res.code == 1) {
          this.httpShow = true;
  
          this.searchGoodsData = res.data;
        }
      });
    },
    toALLCategory() {
      this.$router.push("allCategory");
    },
    handleClick(data, index) {
      this.category_id = data.category_id;
      this.index = index;
      this.getGoodsList();
    },
    qiehuan(index) {
      this.sxIndex = index;
      this.getGoodsList();
    },
    jiage(data, index) {
      this.jgname = "价格：" + data.label;
      this.jgIndex = index;
      this.sxIndex = 3;
      this.order = data.value;
      this.getGoodsList();
    },
    handleCheck() {
      this.getGoodsList();
    },
    pageChange(page) {
      this.page = page;
      this.getGoodsList();
    },
    toGoodsInfo(id) {
      this.$router.push({ path: "goodsInfo", query: { goodsId: id } });
    },
    sousuo() {
      this.$store.state.searchType = 1;
      this.$router.push({ query: { shop_id: "" } });
      location.reload();
    },
    handleRemove() {
      this.maxNum = "";
      this.minNum = "";
      this.checkList = [];
      this.getGoodsList();
    },
  },
};
</script>
<style lang="less" >
.goodsSearch {
  background: #f5f5f5;
  padding: 30px 0;
  .left {
    width: 190px;
    padding-bottom: 60px;
    // height: 1440px;
    margin-right: 20px;
    .l-all {
      display: flex;
      align-items: center;
      border-bottom: 1px solid rgba(0, 0, 0, 0.05);
      padding-bottom: 20px;
      cursor: pointer;
    }
    .l-category {
      // border-bottom: 1px solid rgba(0, 0, 0, 0.05);
      padding-bottom: 20px;
      .item {
        display: flex;
        font-size: 14px;
        padding: 8px 10px;
        color: rgba(0, 0, 0, 0.87);
        cursor: pointer;
      }
      .item-active {
        color: #ee4d2d;
      }
    }
    .l-price {
      border-bottom: 1px solid rgba(0, 0, 0, 0.05);
      padding: 10px 0 20px 0;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.8);
      .el-input--mini .el-input__inner {
        padding: 6px;
      }
    }
    .l-evaluation {
      border-bottom: 1px solid rgba(0, 0, 0, 0.05);
      padding: 10px 0 20px 0;
      font-size: 14px;
      .item {
        padding: 4px 0;
        font-size: 12px;
        .el-rate__icon {
          font-size: 16px;
          margin: 2px;
        }
        .el-icon-star-off {
          font-size: 14px;
          padding: 0 1px;
        }
      }
    }
    .l-condition {
      border-bottom: 1px solid rgba(0, 0, 0, 0.05);
      padding: 10px 0 20px 0;
      font-size: 14px;
    }
  }
  .right {
    width: 990px;
    .top {
      background: rgba(0, 0, 0, 0.03);
      display: flex;
      height: 60px;
      width: 100%;
      padding: 0 20px;
      align-items: center;
      .lefts {
        width: 100%;
        display: flex;
        font-size: 14px;
        color: #555;
        height: 100%;
        align-items: center;
        .l-btn {
          width: 90px;
          text-align: center;
          line-height: 34px;
          height: 34px;
          background: #fff;
          margin-left: 20px;
          cursor: pointer;
        }
        .l-btna {
          width: 200px;
          padding: 0 10px;
          box-sizing: border-box;
          line-height: 34px;
          height: 34px;
          background: #fff;
          margin-left: 20px;
          cursor: pointer;
          display: flex;
          justify-content: space-between;
          align-items: center;
          position: relative;
          .jiageList {
            position: absolute;
            bottom: -69px;
            left: 0;
            background: #fff;
            width: 100%;
            padding: 0 10px;
            display: none;
            z-index: 3;
            .zxc:hover {
              color: #ee4d2d;
            }
          }
        }
        .l-btna:hover .jiageList {
          display: block;
        }
        .l-btn-active {
          background: #ee4d2d;
          color: #fff;
        }
      }
      .r-feny {
        display: flex;
        align-items: center;
        text-align: right;
        font-size: 14px;
      }
    }

    .search-list {
      display: flex;
      flex-wrap: wrap;
      margin-top: 10px;
    }
    .cvb {
      .el-pager li {
        background: transparent;
      }
      .el-pagination button:disabled {
        background: transparent;
      }
      .el-pagination .btn-next,
      .el-pagination .btn-prev {
        background: transparent;
      }
      .el-pager li.active {
        background: #ee4d2d;
        color: #fff;
      }
    }
  }
}
</style>