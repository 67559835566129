<template>
  <div class="search-goods">
    <div class="item" @click="toPush(data.id)">
      <div
        v-if="data.discount != 0"
        style="
          width: 46px;
          height: 42px;
          background-color: rgba(255, 212, 36, 0.9);
          position: absolute;
          right: 0;
          z-index: 1;
          font-size: 14px;
          text-align: center;
          padding: 4px;
        "
      >
        <div style="color: #ee4d2d">
          {{ data.discount }}
        </div>
        <div style="color: #fff">折扣</div>
        <div
          style="
            width: 0;
            height: 0;
            left: 0;
            bottom: -4px;
            position: absolute;
            border-color: transparent rgba(255, 212, 36, 0.9);
            border-style: solid;
            border-width: 0 23px 4px;
          "
        ></div>
      </div>
      <el-image
        style="
          width: 188px;
          height: 188px;
          text-align: center;
          line-height: 200px;
        "
        :src="data.image"
        fit="fit"
      >
        <svg
          slot="error"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 54 61"
          fill="#e5e4e4"
          style="width: 50px; height: 50px"
        >
          <path
            d="M51.2 16.9H38.7C38.7 11.6 36 .6 27 .5 17.4.4 15.2 12.4 15.2 16.9H2.8c-3.4 0-2.7 3.4-2.7 3.4l2.4 33s-.1 7.3 6.3 7.5h36.5c6.2-.4 6.3-7.5 6.3-7.5l2.4-33c0-.1.5-3.5-2.8-3.4zM27.1 4.2c7.1.2 7.9 11.7 7.7 12.6H19.1c-.1-.9.4-12.4 8-12.6zm9.1 44.6c-1 1.7-2.7 3-5 3.7-1.2.4-2.4.5-3.6.5-3.2 0-6.5-1.1-9.3-3.3-.8-.6-1-1.5-.5-2.3.2-.4.7-.7 1.2-.8.4-.1.9 0 1.2.3 3.2 2.4 8.3 4 11.9 1.6 1.4-.9 2.1-2.7 1.6-4.3-.5-1.6-2.2-2.7-3.5-3.4-1-.6-2.1-1-3.3-1.4-.9-.3-1.9-.7-2.9-1.2-2.4-1.2-4-2.6-4.8-4.2-1.2-2.3-.6-5.4 1.4-7.5 3.6-3.8 10-3.2 14-.4.9.6.9 1.7.4 2.5s-1.4.9-2.2.4c-2-1.4-4.4-2-6.4-1.7-2 .3-4.7 2-4.4 4.6.2 1.5 2 2.6 3.3 3.3.8.4 1.5.7 2.3.9 4.3 1.3 7.2 3.3 8.6 5.7 1.2 2.1 1.2 4.9 0 7z"
          /></svg
      ></el-image>
      <div style="padding: 0 10px">
        <div
          style="
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            min-height: 32px;
            margin-bottom: 4px;
          "
        >
          {{ data.title }}
        </div>
        <div style="margin-bottom: 6px">
          <el-tag size="mini" type="danger" effect="plain">Local Seller</el-tag>
        </div>
        <div
          style="
            color: #ee4d2d;
            margin-bottom: 8px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          "
        >
          <span style="font-size: 14px">{{ data.price }}</span>
        </div>
        <div style="display: flex; align-items: center">
          <el-rate disabled v-model="value1" style="margin-top: 4px"></el-rate>
          <div>售 {{ data.five_star }}</div>
        </div>
        <div style="margin-top: 8px; color: rgba(0, 0, 0, 0.65)">中国大陆</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "SearchGoods",
  props: ["data"],
  data() {
    return {
      value1: 5,
    };
  },
  methods: {
    toPush(id) {

      this.$parent.toGoodsInfo(id);
    },
  },
};
</script>
<style lang="less" >
.search-goods {
  width: 198px;
  height: 338px;
  padding: 0 5px;
  margin: 5px 0;
  .item {
    width: 100%;
    height: 100%;
    background: #fff;
    font-size: 12px;
    position: relative;
    .el-rate__icon {
      font-size: 14px;
      margin: 0;
    }
  }
  .item:hover {
    box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.12);
    transform: translateY(-2px);
    transition: all 200ms ease;
    z-index: 1;
    cursor: pointer;
  }
}
</style>